<template>
    <div class="registerType">
        <router-link to="/registerPersonal">
            <div class="typeRoute">
                <div class="user">
                    <div style="margin-top: 75px;">
                        <img src="@/assets/images/icon_left.png" alt="">
                    </div>
                    <img src="@/assets/images/yonghu.png" alt="">
                    <div style="margin-top: 75px;">
                        <img src="@/assets/images/icon_right.png" alt="">

                    </div>
                </div>
                <p class="text">个人用户注册</p>
            </div>
        </router-link>

            <router-link to="/registerCompany">
                <div class="typeRoute">
                    <div class="user">
                        <div style="margin-top: 75px;">
                            <img src="@/assets/images/icon_left.png" alt="">
                        </div>
                        <img src="@/assets/images/government.png" alt="">
                        <div style="margin-top: 75px;">
                            <img src="@/assets/images/icon_right.png" alt="">

                        </div>
                    </div>
                    <p class="text">单位/企业注册</p>
                </div>

            </router-link>
    </div>
</template>

<script>
    export default {
        name: "register"
    }
</script>

<style scoped type="text/scss" lang="scss">
  .registerType {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    line-height: 1.4;
    width: 1084px;
    height: 711px;
    align-items: center;
    margin: 20px auto;
    background: url("../../assets/images/register_bg.png") no-repeat center center;
    a{
      color: #fff;
    }
    a:hover {
      color: #ffffff;
    }

    .typeRoute {
      width: 338px;
      height: 202px;
      background: #ff547b;
      border-radius: 20px;
      padding: 27px;
      cursor: pointer;
      .text{
        text-align: center;
        margin-top: 20px;
        font-size: 21px;
      }
      .user {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        font-size: 21px;
      }
    }

    > :first-child {
      margin-right: 36px;
    }
  }
</style>
